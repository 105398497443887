const state = {
	userInfo: {
		rouerList: [],
		buttonList: []
	},
	callPhone: {
		visable: false,
		phone: '',
		isCalling: false
	}
}

const mutations = {
	SET_USERINFO: (state, status) => {
		Object.assign(state.userInfo, status)
	},
	SET_CALLPHONE: (state, status) => {
		state.callPhone = status
	}
}

const actions = {
	setUserInfo ({ commit }, val) {
		commit('SET_USERINFO', val)
	},
	setCallPhone ({ commit }, val) {
		commit('SET_CALLPHONE', val)
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}