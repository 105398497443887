import Vue from 'vue'
import VueRouter from 'vue-router'
import staticRoute from './staticRoute.js'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: staticRoute // staticRoute为静态路由，不需动态添加
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

let isToken = true
router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    next('/login')
  } else {
    // 定义isToken为true和缓存中的路由不为空时添加路由
    let permissionRouting = JSON.parse(sessionStorage.getItem('permissionRouting')) || []
    if (isToken && permissionRouting.length > 0) {
      const accessRouteses = permissionRouting
      let firstRoute = {
        path: '/garbageManagement',
        name: 'garbageManagement',
        component: resolve => require(['@/views/garbageManagement.vue'], resolve),
        children: []
      }
      // 动态路由循环解析和添加
      let rouerList = []
      accessRouteses.forEach(v => {
        if (v.children && v.children.length > 0) {
          rouerList = [...rouerList, ...routerChildren(v.children)]
        } else {
          v.component = routerCom(v.resFileAddress)
          v.path = v.resUri
          v.name = v.resUri
          rouerList.push(v)
        }
      })
      firstRoute.children = rouerList
      console.log(firstRoute, '当前有权限的路由')
      router.addRoute(firstRoute); // 添加
      isToken = false // 将isToken赋为 false ，否则会一直循环，崩溃
      next({
        ...to, // next({ ...to })的目的,是保证路由添加完了再进入页面 (可以理解为重进一次)
        replace: true, // 重进一次, 不保留重复历史
      })
    } else {
      next()
    }
  }
})

function routerCom (path) { // 对路由的component解析
  return (resolve) => require([`@/views/module${path}`], resolve)
}

function routerChildren (children) { // 对子路由的component解析
  if (children.length > 0) {
    children.forEach(v => {
      v.component = routerCom(v.resFileAddress)
      v.path = v.resUri
      v.name = v.resUri
      if (v.children && v.children != []) {
        v.children = routerChildren(v.children)
      }
    })
  } else {
    children = []
  }
  return children
}


export default router