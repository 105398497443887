const staticRoute = [
	{
		path: '/login',
		name: '/login',
		component: resolve => require(['@/components/login.vue'], resolve)
	},
	{
		path: '/404',
		name: '/page404',
		component: resolve => require(['@/components/404.vue'], resolve)
	}
]

export default staticRoute
